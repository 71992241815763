exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paintings-canvas-js": () => import("./../../../src/pages/paintings/canvas.js" /* webpackChunkName: "component---src-pages-paintings-canvas-js" */),
  "component---src-pages-paintings-js": () => import("./../../../src/pages/paintings.js" /* webpackChunkName: "component---src-pages-paintings-js" */),
  "component---src-pages-paintings-projects-contentful-painting-series-slug-js": () => import("./../../../src/pages/paintings/projects/{contentfulPaintingSeries.slug}.js" /* webpackChunkName: "component---src-pages-paintings-projects-contentful-painting-series-slug-js" */),
  "component---src-pages-paintings-projects-js": () => import("./../../../src/pages/paintings/projects.js" /* webpackChunkName: "component---src-pages-paintings-projects-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-photos-series-contentful-photo-series-slug-js": () => import("./../../../src/pages/photos/series/{contentfulPhotoSeries.slug}.js" /* webpackChunkName: "component---src-pages-photos-series-contentful-photo-series-slug-js" */),
  "component---src-pages-photos-series-js": () => import("./../../../src/pages/photos/series.js" /* webpackChunkName: "component---src-pages-photos-series-js" */),
  "component---src-pages-photos-singles-js": () => import("./../../../src/pages/photos/singles.js" /* webpackChunkName: "component---src-pages-photos-singles-js" */)
}

